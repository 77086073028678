export default [
    {
        key: 'code',
        label: 'field.itemTypeCode',
        type: 'text',
        operator: '%',
    },
    {
        key: 'name',
        label: 'field.typeName',
        type: 'text',
        operator: '%',
    },
    {
        key: 'isEnable',
        label: 'field.status',
        rules: 'required',
        type: 'radio',
        cast: 'boolean',
        options: [
          { text: 'status.active', value: '1' },
          { text: 'status.inactive', value: '0' },
        ],
      },
];
